.website-section.work{
	transition: opacity .4s ease;

	.desc{
		max-width: 880px;
		margin: 0 auto 60px;
		font-family: $italic;
		font-size: 28px;
		line-height: 48px;

		a{
			position: relative;
			display: inline-block;

			&:after{
				content: "";
				position: absolute;
				bottom: 8px; left: 0;
				width: 95%; height: 1px;
				background: $black;
			}
		}
	}


	.list-projects{
		padding-bottom: 60px;
		
		.list-item{
			display: block;
			font-family: $demi;
			font-size: 84px;
			letter-spacing: 1px;
			line-height: 100px;

			a{
				position: relative;
				display: block;
				width: 100%;
				color: inherit;
				transition: all .5s ease;
			}
		}

		&.hovered{
			.list-item a{
				opacity: .07;

				&:hover{
					opacity: 1;
					transition-duration: .8s;
					transition-delay: .1s;
				}
			}
		}
	}

	.inactive{
		.list-projects{
			opacity: 0;
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $maxPageWidth) {
	.website-section.work .list-projects .list-item{
		font-size: 74px;
		line-height: 90px;
	}
}

@media 	only screen  and (max-width: $desktopSmall) {
	.website-section.work .list-projects .list-item{
		font-size: 64px;
		line-height: 80px;
	}
	.website-section.work .desc{
		font-size: 22px;
		line-height: 42px;
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	.website-section.work{
		.desc{
			font-size: 20px;
			line-height: 34px;
			margin-bottom: 40px;
		}

		.list-projects{
			padding-bottom: 80px;
			
			.list-item{
				font-size: 28px;
				line-height: 38px;
				letter-spacing: 0;
				margin-bottom: 10px;
			}
		}

		.inactive{
			.list-projects{
				opacity: 1;
			}
		}
	}
}