.website-section.home{
	transition: opacity .4s ease;
	padding-bottom: 100px;

	.desc{
		// width: 80%;
		max-width: 1100px;
		margin: 0 auto;
		font-family: $italic;
		font-size: 48px;
		line-height: 70px;
	}

	.inactive{
		opacity: 0;
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////


@media 	only screen  and (max-width: $desktopSmall) {
	.website-section.home .desc{
		font-size: 40px;
		line-height: 62px;
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	.website-section.home .desc{
		font-size: 26px;
		line-height: 38px;
	}
}