#popin-project{
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background: $dark;
	color: $white;
	z-index: 100;
	overflow:hidden;
	transition: all .7s $easeOutExpo;

	.fullscreen-container{
		position: absolute;
		width: 100%; height: 100%;
		left: 0; top: 0;
		transition: all .7s $easeOutExpo;
	}

	.to-fill{
		position: relative;
		width: 100%; height: 100%;
	}

	.classic-btn{
		color: $white;
		border-color: $white;
		padding: 0; border: 0;
		padding-left: 100px;
	}

	.content-popin{
		position: relative;
		padding: 80px 0;
		margin: 0 auto;
		top: 0; left: 0;
		width: 100%; height: 100%;
		text-align: left; 
		transition: all .9s $easeOutExpo .1s;
		overflow-y: scroll;
		opacity: 1;

		.header{
			position: relative;
			width: 720px;
			margin: 0 auto 100px;

			.project-title{
				position: relative;
				width: 420px;
				font-family: $demi;
				font-size: 58px;
				line-height: 58px;
				letter-spacing: 1px;
			}

			.mentions{
				padding-left: 100px;
				.mention{
					display: inline-block;
					margin-right: 100px;
					vertical-align: top;
					font-family: $italic;
					font-size: 16px;
					line-height: 26px;

					strong{
						font-family: $serif;
						text-transform: capitalize;
					}

					&:last-of-type{
						margin-right: 0;
					}
				}
			}

			.desc{
				position: relative;
				font-family: $italic;
				font-size: 22px;
				line-height: 32px;
				padding-left: 100px;	
				margin: 60px 0;	

				&:before{
					content: "";
					position: absolute;
					top: 14px; left: 0px;
					width: 75px; height: 1px;
					background: $white;
				}
			}

			.nav-btn{
				position: absolute;
				top: 50%;
				transform: translateX(-50%);
				display: block;
				width: 30px; height: 30px;

				svg{
					fill: $white;
				}

				&.next{
					right: -120px;
					transform: rotate(90deg);
				}

				&.prev{
					left: -120px;
					transform: rotate(-90deg);
				}
			}
		}

		.medias{
			width: 66%;
			max-width: 1140px;
			margin: 0 auto;
			opacity: 1;
			transition: all .9s ease .4s;
			text-align: center;

			.media{
				display: inline-block;
				max-width: 100%;
				margin-bottom: 140px;
				box-shadow: 0px 0px 82px 14px rgba(0,0,0,0.05);

				&:last-of-type{
					margin-bottom: 0px; 
				}
			}
		}
	}

	.popin-close-btn{
		top: 50px; right: 80px;
		&:after, &:before{
			background: $white;
		}
	}

	.intro .to-anim{
		position: relative;
		left: 0px;
		opacity: 1;
		transition: all .6s ease;
		$elements: 6;
		@for $i from 0 to $elements {
			&:nth-child(#{$i}) {
				transition-delay: $i*.07s; 
			}
		}
	}

	&.off{
		// left: -100%;
		visibility: hidden;
		opacity: 0;
		z-index: 0;
		transition-delay: 0s;
		transition-duration: 0s;

		.fullscreen-container{
			// left: 100%;
			transition-delay: 0s;
			transition-duration: 0s;
			visibility: hidden;
			opacity: 0;
		}

		.content-popin {
			.medias{
				opacity: 0;
			}
		}

		.intro .to-anim{
			left: -50px;
			opacity: 0;
		}
	}

	&.close{
		// left: 100%;
		opacity: 0;
		visibility: hidden;
		transition-duration: .5s;

		.fullscreen-container{
			transition-duration: .5s;
		}

		.content-popin{
			transition-duration: 0s;
			transition-delay: 0s;

			.medias{
				transition-duration: 0s;
				transition-delay: 0s;
			}
		}
	}

	&.updating{
		.intro .to-anim{
			transition-duration: .3s;
		}

		.content-popin {
			opacity: 0;	
			transition-duration: .4s;
			transition-delay: .4s;

			.medias{
				opacity: 0;
				// transition-duration: .6s;
			}
		}

		&.updating{
			&__prev{
				.intro .to-anim{
					left: -50px;
					opacity: 0;
				}
			}

			&__next{
				.intro .to-anim{
					left: 50px;
					opacity: 0;
				}
			}
		}
	}

	&.reset{

		.content-popin {
			opacity: 0;	
			transition-duration: .0s;
		}

		&.updating{
			&__next{
				.intro .to-anim{
					left: -50px;
					opacity: 0;
				}
			}

			&__prev{
				.intro .to-anim{
					left: 50px;
					opacity: 0;
				}
			}
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $xsMobile) {
	#popin-project{
		.content-popin{
			padding: 60px 0;

			.header{
				width: 100%;
				padding: 0 20px;
				margin: 0 auto 60px;

				.project-title{
					width: 100%;
					font-size: 42px;
					line-height: 42px;
					letter-spacing: 1px;
				}

				.mentions{
					padding-left: 80px;

					.mention{
						display: block;
						margin-right: 0;
						margin-bottom: 10px;
						font-size: 16px;
						line-height: 26px;

						&:last-of-type{
							margin-bottom: 0; 
						}
					}
				}

				.desc{
					font-size: 18px;
					line-height: 28px;
					padding-left: 80px;	
					margin: 50px 0;	

					&:before{
						width: 55px;
					}
				}

				.classic-btn{
					padding-left: 80px;
				}

				.nav-btn{
					display: none;
				}
			}

			.medias{
				width: 90%;

				.media{
					margin-bottom: 40px;
				}
			}
		}

		.popin-close-btn{
			top: 20px; right: 20px;
		}
	}
}