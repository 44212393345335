@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader{
	position: fixed;
	top: 0; left: 0;
	z-index: 1000;
	width: 100%; height: 100%;
	background: rgba(0, 0, 0, .8);
	transition: all .3s ease;

	.loading {
		position: absolute;
		top: 50%; left: 50%;
		border-radius: 50%;
		width: 40px; height: 40px;
		margin-top: -20px; margin-left: -20px;
		border: .25rem solid rgba(255, 255, 255, 0.2);
		border-top-color: white;
		animation: spin 1s infinite linear;
	}

	&.off{
		opacity: 0;
		visibility: hidden;
	}
}