.popin-close-btn{
	position: absolute;
	right: 40px; top: 20px;
	display: block;
	width: 24px; height: 24px;
	cursor: pointer;
	z-index: 2;

	&:after, &:before{
		content: "";
		position: absolute;
		left: 50%; width: 50%;
		width: 1px; height: 22px;
		background: $black;
		transform-origin: 50% 50%;
		transition : all .3s ease;
	}
	&:after{
		transform: rotate(-45deg);
	}

	&:before{
		transform: rotate(45deg);
	}


	&:hover{
		&:after, &:before{
			transform: rotate(-90deg);
		}
		
	}
}

.classic-btn{
	display: inline-block;
	font-family: $demi;
	font-size: 18px;
	margin-top: 56px;
	padding: 12px 28px;
	border: 1px solid $black;
	color: $black;
}