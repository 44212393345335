html { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
body { margin: 0; padding:0; -webkit-overflow-scrolling: touch; }


/* -------------------------------------------- */
/* ----  UTILS */
/* -------------------------------------------- */
.ir {display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat;  direction: ltr; *line-height: 0; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.fade-hidden { opacity: 0; display: none !important; }
.fade { opacity: 0; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }
.clear { clear : both; }



/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/
/**************************************************************************************/


body
{
	width: 100%;
	padding:0;
	font-family: $arial;
	line-height: 1.4;
	font-size: 18px;
	background: $white;
	transition: background .6s ease;
	overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6
{
	font-weight: normal;
}

a{
	color:inherit;
	text-decoration: none;
}

b, strong
{
	// font-family: $bold;
}

i
{
	// font-family: $italic;
	font-style: italic;
}

p{
	font-size: 17px;
	line-height: 28px;
}


#main-container
{
	position:relative;
	top: 0; left:0;
	display: block;
	width:100%;
	overflow: hidden;

	// &.no-scroll{		
	//     position: fixed;
	//     overflow-y: scroll;
	// }
}

.content-to-center{
	width: 960px;
	margin: 0 auto;
}

.keepImgRatio{
	position: absolute;
	left: 0; top: 0;
}

.is-fullscreen{	
	background-size: cover;
	background-position: center center;
	background-repeat : no-repeat;
	background-attachment: fixed;
}

.not-on-mobile{
	display: block;
}

.svg{
	background-size: 100%;
}





////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (min-width: $xsMobile) {
	body{
		.not-on-desktop{
			display: none !important;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	body{
		.not-on-mobile{
			display: none !important;
		}
	}
}