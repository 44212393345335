.page-container{
	position: relative;
	height: 100%;

	.website-section{
		position: relative;
		z-index: 2;
		opacity: 1;
		visibility: visible;
		text-align: center;
		max-width: 1300px;
		margin: 0 auto;
		padding: 0 100px;

		&.inactive{
			position: absolute;
			top: 0; left: 0;
			opacity: 0;
			z-index: 1;
			visibility: hidden;
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $xsMobile) {
	.page-container{

		.website-section{
			padding: 0 20px;
		}
	}
}