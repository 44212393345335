.page-container{

	.website-section.photos{
		padding: 0;
		width: 100%;
		max-width: 100%;
		transition: opacity .4s ease;

		.list-galleries{
			// .item{
			// }

			.link{
				position: relative;
				display: block;
				width: 100%; height: 680px;
				margin: 0 auto 145px;
				 // max-height: 680px;
				overflow: hidden;
				// margin-bottom: 140px; 
				opacity: .4;
				transition: opacity .3s ease;

				figure{
					position: relative;
					margin: 0 auto;
					width: 100%; height: 100%;
					transition: all .9s ease;
					transform: translateZ(0) scale(1.08);
					-webkit-backface-visibility: hidden;
					background-size: cover;
					background-repeat: none;
					background-position: center center;
				}

				&:hover{
					figure{
						transform: translateZ(0) scale(1);
					}
				}
				
				.desc{
					position: absolute;
					top: 50%; left: 0;
					width: 100%;
					transform: translateY(-50%);
					z-index: 2;
					color: $white;
					transition: all .3s ease;

					.name{
						position: relative;
						font-family: $demi;
						font-size: 128px;
						opacity: 1;
						top: 0;
						transition: all .3s ease;
					}

					.count{
						position: relative;
						font-family: $italic;
						font-size: 36px;
						opacity: 1;
						top: 0;
						transition: all .3s ease .2s;
					}
				}
				
				&.selected{
					.desc{
						.name, .count{
							opacity: 0;
							top: -20px;
						}
					}

					figure{
						transform:  translateZ(0) scale(1);
						// width: 882px;
					}
				}

				&.active{
					opacity: 1;
				}
			}
		}

		.inactive{
			.list-galleries{
				opacity: 0;
			}
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $largeDesktop) {
	.page-container .website-section.photos .list-galleries .link .desc{
		.name{
			font-size: 116px;
		}

		.count{
			font-size: 30px;
		}
	}
}

@media 	only screen  and (max-width: $maxPageWidth) {
	.page-container .website-section.photos .list-galleries .link .desc{
		.name{
			font-size: 94px;
		}

		.count{
			font-size: 26px;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	.page-container{
		.website-section.photos{
			.list-galleries{

				.link{
					width: 80% !important;
					height: 300px !important;
					margin: 0 auto 60px;
					opacity: 1;
					
					.desc{
						.name{
							font-size: 48px;
							line-height: 56px;
							margin-bottom: 10px; 
						}

						.count{
							font-size: 16px;
						}
					}
					
					&.selected{
						.desc{
							.name, .count{
								opacity: 0;
								top: -20px;
							}
						}

						figure{
							transform:  translateZ(0) scale(1);
							// width: 882px;
						}
					}

					&.active{
						opacity: 1;
					}
				}
			}

			.inactive{
				.list-galleries{
					opacity: 0;
				}
			}
		}
	}

}