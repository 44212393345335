//Variables

$fontBase			: 16;
$arial          	: Arial, "Helvetica Neue", Helvetica, sans-serif; 
//
$serif            	: 'bodoniBold';
$italic            	: 'bodoniItalic';
$bold           	: 'avantgardeBold';
$demi           	: 'avantgardeDemi';



@font-face {
	font-family: 'bodoniBold';
	src: url('../fonts/BauerBodoniStdBold.eot');
	src: local('☺'), url('../fonts/BauerBodoniStdBold.woff') format('woff'), url('../fonts/BauerBodoniStdBold.ttf') format('truetype'), url('../fonts/BauerBodoniStdBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'bodoniItalic';
	src: url('../fonts/BauerBodoniStdItalic.eot');
	src: local('☺'), url('../fonts/BauerBodoniStdItalic.woff') format('woff'), url('../fonts/BauerBodoniStdItalic.ttf') format('truetype'), url('../fonts/BauerBodoniStdItalic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avantgardeBold';
	src: url('../fonts/ITCAvantGardeProBold.eot');
	src: local('☺'), url('../fonts/ITCAvantGardeProBold.woff') format('woff'), url('../fonts/ITCAvantGardeProBold.ttf') format('truetype'), url('../fonts/ITCAvantGardeProBold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'avantgardeDemi';
	src: url('../fonts/ITCAvantGardeProDemi.eot');
	src: local('☺'), url('../fonts/ITCAvantGardeProDemi.woff') format('woff'), url('../fonts/ITCAvantGardeProDemi.ttf') format('truetype'), url('../fonts/ITCAvantGardeProDemi.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}