#popin-gallery{
	position: fixed;
	top: 0; left: 0;
	width: 100%; height: 100%;
	transform: translateZ(0) scale(1.0, 1.0);
	z-index: 100;
	background: transparent;
	font-family: $demi;
	color: $black;
	overflow: hidden;
	transition-property: all;
	transition-timing-function : $easeOutExpo;
	transition-delay: 0s;
	transition-duration: 0s;
	-webkit-backface-visibility: hidden;
	top: 0; left: 0;
	width: 0%; height: 100%;

	.mobile-instructions{
		position: fixed;
		top: 0; left: 0;
		visibility: hidden;
		opacity: 0;
		width: 100%; height: 100%;
		background: rgba(0, 0, 0, .7);
		transition: all .3s ease;

		.txt{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			text-align: center;
			color: $white;
			font-family: $italic;
			font-size: 20px;
			min-width: 320px;
		}
	}

	.popin-close-btn{
		top: 5%;
		margin-top: -12px;
	}

	.fullscreen-container{
		position: fixed;
		width: 100%; height: 100%;
		top: 0; left: 0;
	}

	.to-fill{
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
	}

	.left-part{
		position: absolute;
		top: 15%; left: 0;
		height: 70%;

		.infos{
			position: absolute;
			top: 0; left: 100px;
			width: 160px; height: 100%;
			text-align: left;
			transition: all .3s ease .3s;
			opacity: 0;

			.gallery-title{
				font-size: 28px;
				margin-bottom: 20px;
			}

			.gallery-desc{
				// display: none;
				font-family: $italic;
				font-size: 16px;
				margin-bottom: 20px;
			}

			.nav-btn{
				display: inline-block;
				vertical-align: middle;
				width: 24px; height: 24px;
			}		

			hr{
				display: block;
				width: 18px; height: 1px;
				border: 0; margin: 20px 0;
				background: $black;
				opacity: .7;
			}

			.next-gallery{
				font-family: $italic;
			}
		}

		.gallery-index{
			display: inline-block;
			vertical-align: middle;
			position: relative;
			font-size: 18px;
			margin: 0 5px;
		}	

		.color-div{
			position: absolute;
			top: 0; left: 0;
			height: 100%; width: 40px;
			transition: all .6s ease .1s;
			opacity: 0;
			z-index: -1;

			&.prev-image{ 
				left: 0;
			}
		}	

		.to-bottom{
			position: absolute;
			bottom: 0; left: 0;
		}
	}

	.nav-gallery{
		right: 30px;

		hr{
			background: $black;
		}

		svg{
			width: 30px; height: 24px;
			fill: $black;
		}
	}

	.nav-btn{
		width: 30px; height: 30px;
		z-index: 10;

		&.next{
			transform: rotate(90deg);
		}

		&.prev{
			transform: rotate(-90deg);
		}
	}

	.gallery-bullets{
		position: relative;
		width: 100%;
		margin-bottom: 20px;
		// text-align: center;

		li{
			display: inline-block;
			width: 8px; height: 8px;
			margin: 0 12px 0 0;
			background: transparent;
			border: 1px solid $black;
			border-radius: 50%;
			transition: all .3s ease .2s;
			cursor: pointer;

			&.active{
				// margin: 0 3px;
				background: $black;
			}
		}
	}


	.diaporama-container{
		position: absolute;
		top: 15%; left: 50%;
		transform: translateX(-50%);
		height: 70%; width: 800px;
		opacity: 1;
		transition-property: width, height, opacity, left;
		transition-duration: .6s;
		transition-delay: .3s;
		transition-timing-function: $easeInOutQuart;
		-webkit-backface-visibility: hidden;

		.loader{
			transition: all .3s ease;
			opacity: 0;
			visibility: hidden;
			z-index: 1000;
		}

		&.is-loading{
			.loader{
				opacity: 1;
				visibility: visible;
			}
		}

		&.cursor-prev{
			cursor:  url(../img/prev-cursor.png) 10 10, auto;
		}

		&.cursor-next{
			cursor:  url(../img/next-cursor.png) 10 10, auto;
		}

		.nav-btn{
			display: none;
			position: absolute;
			top: 50%; margin-top: -15px;

			&.next{
				right: -50px;
			}

			&.prev{
				left: -50px;
			}
		}

		.moving{
			position: relative;
			width: 100%; height: 100%;
			overflow: hidden;
		}

		.slide{
			position: absolute;
			top: 0; right: -80px;
			// display: none;
			// visibility: hidden;
			width: 80px; height: 100%;
			z-index: 2;
			background: $black;
			overflow: hidden;
			transition-property: left, right, width;
			transition-duration: .0s;
			transition-timing-function: $easeInOutQuart;
			background: transparent;

			.content{
				width: auto; height: 100%;
				text-align: right;

				img{
					height: 100%;
					image-rendering: crisp-edges;
					-webkit-backface-visibility: hidden;	
				}
			}

			&.active{
				width: 100%;
				right: 0;
				// visibility: visible;
				transition-duration: .6s;
			}

			&.out{
				width: 100%;
				right: 120px;
				z-index: 1;
				// visibility: visible;						
				transition-duration: .6s;
			}
		}

		&.going-negative{
			.slide{
				left: -80px;
				right: auto;

				.content{
					text-align: left; 
				}

				&.active{
					width: 100%;
					left: 0;
					right: auto;
				}

				&.out{
					left: 120px;
					right: auto;
				}						
			}
		}
	}

	&.off{
		transition-delay: 0s;
		transition-duration: 0s;
		visibility: hidden;
		opacity: 0;
	}

	&.in-transit{
		opacity: 1;
		visibility: visible;
		background: $white;
		top: 0 !important; left: 0 !important;
		width: 100% !important; 
		height: 100% !important;
		transition-duration: 1s;

		.diaporama-container{
			left: 59%;
		}

		.infos{
			left: 130px;
			opacity: 1;
		}

		.color-div{
			width: 80px;
			opacity: .9;
		}
	}

	&.opened{
		.diaporama-container{
			transition-delay: 0s;
		}
	}

	&.closed{
		transition-delay: .4s;
		transition-duration: 1s;
	}

	&.reset{
		.infos{
			left: 80px !important;
			opacity: 0 !important;
		}

		.color-div{
			width: 30px !important;
			opacity: 0 !important;
		}

		.diaporama-container{
			opacity: 0 !important;
		}
	}

	&.portrait{
		&.in-transit{
			.diaporama-container{
				left: 50%;

				.nav-btn{
					display: block;
				}
			}


			.nav-gallery{
				display: none;
			}

			.infos{
				display: none;
			}

			.color-div{
				display: none;
			}
		}
	}

	.diapo-mobile-container{
		position: absolute;
		width: 100%; height: 100%;
		overflow-y: scroll;

		img{
			display: block;
			width: 80%;
			margin: 40px auto;
		}
	}
}






////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen  and (max-width: $desktopSmall) {
	#popin-gallery{
		.gallery-title{
			font-size: 24px;
			margin-bottom: 16px;
		}

		.gallery-desc{
			font-size: 14px;
			margin-bottom: 16px;
		}

		.gallery-bullets{
			margin-bottom: 16px;

			li{
				width: 6px; height: 6px;
				margin: 0 10px 0 0;
			}
		}

		.left-part{
			top: 17%; height: 66%;
		}

		.diaporama-container{
			top: 17%;
			height: 66%; width: 800px;
		}

		&.in-transit{
			.diaporama-container{
				left: 57%;
			}

			.infos{
				left: 80px;
			}

			.color-div{
				width: 50px;
			}
		}
	}
}

@media 	only screen and (orientation: portrait) and (max-width: 1000) and (min-width: 768) {
	#popin-gallery{
		.mobile-instructions{
			visibility: visible;
			opacity: 1;
		}
	}
}

@media 	only screen  and (max-width: $xsMobile) {
	#popin-gallery{
		.popin-close-btn{
			top: 20px;
			right : 5px;
			margin-top: 0;
		}
	}
}