#main-menu{
	width: 100%;
	text-align: center;
	padding: 120px 0 80px;

	.elt{
		display: inline-block;
		vertical-align: text-top;

		&.logo{
			width: 85px;
			margin-top: -50px;


			a{
				display: block;
				width: 90%;
				height: 75px;
				margin: 0 auto;
				svg{
					display: block;
					height: 100%;
					width: 100%;
				}
			}

			hr{
				width: 80%;
				height: 1px;
				background: $black;
				border: 0;
				margin: 12px auto;
				opacity: .6;
			}

			.brand{
				font-family: $bold;
				font-size: 16px;
				letter-spacing: 1px;
			}

			.name{
				font-family: $italic;
				font-size: 11px;
				margin-top: 8px;
			}
		}

		&.links{
			// width: 370px;
			width: 180px;

			&.left{
				text-align: left;
			}
			&.right{
				text-align: right;
			}
		}

		.link{
			display: inline-block;
			text-align: center;
			width: auto;
			font-family: $serif;
			font-size: 16px;

			svg{
				width: 32px;
				height: 26px;
				display: block;
				margin: auto;
			}
		}
	}
}


#bullets-gallery{
	position: fixed;
	top: 50%;
	width: 24px;
	z-index: 10;
	transform: translateY(-50%);
	text-align: center;
	opacity: 1;
	transition: all .3s ease;
		margin-left: 0px;

	.bullet{
		margin: 0 auto;
		padding: 8px 8px;
		cursor: pointer;

		span{
			display: block;
			width: 8px; height:8px;
			border: 1px solid $white;
			border-radius: 50%;
			transition: all .2s ease;
			background: transparent;
		}

		&:hover{
			span{
				background: $white;
			}
		}

		&.active{
			span{
				background: $white;
			}
		}
	}

	&.off{
		opacity: 0;
		margin-left: -20px;
	}
}


#nav-gallery, .nav-gallery{
	position: fixed;
	top: 50%;
	margin-top: -55px;
	width: 50px; height: 110px;
	z-index: 10;
	text-align: center;
	opacity: 1;
	transition: all .3s ease;
		margin-left: 0px;

	a{
		display: inline-block;
		width: 30px; height: 30px;
		margin: 0 auto;
		opacity: 1;
		transition: all .2s ease;

		&:hover{
			opacity: .7;
		}

		&.off{
			opacity: .2;
		}
	}

	hr{
		display: block;
		margin: 30px auto;
		width: 20px; height: 1px;
		border:0;
		background: $black;
		opacity: .3;
	}

	&.off{
		opacity: 0;
		margin-left: 20px;
	}
}



body.photos{
	background: $photosBack;

	#main-menu{
		color: $photosFont;
		transition: all .3s ease;

		.elt.logo hr{
			background: $photosFont;
			transition: all .3s ease;
		}

		svg{
			fill: $photosFont;
			transition: all .3s ease;
		}
	}

	#nav-gallery{
		svg{
			fill: $photosFont;
		}

		hr{
			background: $photosFont;
		}
	}
}

body.work{
	background: #efeff0;
	color: $dark; 

	#main-menu{
		color: $dark;

		.elt.logo hr{
			background: $dark;
		}

		svg{
			fill: $dark;
		}
	}
}

body.dark{
	background: $dark;

	#main-menu{
		color: $white;

		.elt.logo hr{
			background: $white;
		}

		svg{
			fill: $white;
		}
	}
}



////////////////////////////////////////////////////////////////
///////////////////////// MEDIAQUERIES /////////////////////////
////////////////////////////////////////////////////////////////

@media 	only screen and (max-width: $xsMobile) {
	#main-menu{
		padding: 80px 0 40px;

		.elt{
			&.logo{
				width: 90px;
				margin-top: -58px;

				a{
					width: 70%;
					height: 75px;
				}

				hr{
					width: 70%;
				}
			}

			&.links{
				width: 90px;
			}

			.link{
				svg{
					width: 28px;
					margin: 0 auto 6px;
				}
			}
		}
	}

	#bullets-gallery{
		display: none;
	}

	#nav-gallery, .nav-gallery{
		display: none;
	}
}